import { useEffect } from "react";
import "./App.css";
import Translate from "./components/Translate";

function App() {
	useEffect(() => {
		console.log("App started");
	}, []);

	return (
		<>
			<Translate />
		</>
	);
}

export default App;
