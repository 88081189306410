import React, { useEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

export default function FHGuestures({
	guestureHander,
	fileName,
	showGuesture,
	fhAudio,
}) {
	// const audioRef = useRef(null);
	const handleEventPlayer = (event) => {
		// if (event === "loop") {
		// 	guestureHander();
		// }
	};
	// useEffect(() => {
	// 	audioRef?.current?.play().catch((error) => {
	// 		// Autoplay was prevented, user interaction required
	// 		console.log(
	// 			"Autoplay was prevented. Please interact with the document first.",
	// 			error
	// 		);
	// 	});
	// }, [showGuesture]);

	return (
		<div className='lottie-container'>
			{/* {showGuesture && <audio ref={audioRef} src={fhAudio} />} */}
			<Player autoplay loop src={fileName} onEvent={handleEventPlayer} />
		</div>
	);
}
