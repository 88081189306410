import React, { useEffect, useRef, useState } from "react";
import Image from "../assets/image.svg";
import Me from "../assets/me.svg";
import Other from "../assets/others.svg";
import Blue from "../assets/blue.json";
import FHGuestures from "../components/FHGuestures";
import axios from "axios";
import { get } from "lodash";

const SpeechRecognition =
	window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;

const Translate = () => {
	const [translatedText, setTranslatedText] = useState("");
	const note = useRef("");
	const lastResult = useRef("");
	// const [note, setNote] = useState(null);
	const micLanguage = useRef("kn-IN");
	const [micStatus, setMicStatus] = useState(false);

	useEffect(() => {
		// console.log("Use effect is called");
		mic.lang = micLanguage.current;
		mic.onresult = (event) => {
			// console.log("Results... ", event.results);
			// const newTranscript = Array.from(event.results)
			// 	.map((result) => result[0].transcript)
			// 	.join("");
			lastResult.current = Array.from(event.results);
			const transcript = lastResult.current
				.map((r) => {
					if (r.isFinal === false) {
						// console.log("Final transcript", r[0].transcript);
						return r[0].transcript;
					} else {
						return "";
					}
				})
				.join("");

			if (transcript.length > 0) {
				note.current = transcript;
			}

			// console.log(
			// 	"audio",
			// 	transcript,
			// 	note.current,
			// 	get(
			// 		lastResult.current,
			// 		`[${lastResult.current.length - 1}][0].transcript`
			// 	)
			// );
		};

		mic.onend = (event) => {
			// console.log("Speech recognition ended", event.results);
			mic.stop();
			setMicStatus(false);
			translate();
		};

		if (micStatus) {
			mic.start();
		}

		return () => {
			mic.stop();
		};
	}, [micStatus]);

	const translate = () => {
		let text =
			note.current ||
			get(
				lastResult.current,
				`[${lastResult.current.length - 1}][0].transcript`
			);
		let data = {
			q: text,
			source: micLanguage.current === "hi-IN" ? "hi" : "kn",
			target: micLanguage.current === "hi-IN" ? "kn" : "hi",
		};
		let apiKey = "AIzaSyA8VYJ18TQNhTRsSn_70YJgqrPVSWvsc-A";

		axios
			.post(
				`https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
				data
			)
			.then((res) => {
				// console.log(res, "converted result");
				setTranslatedText(res?.data.data.translations[0].translatedText);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		let utterance;
		if (translatedText) {
			// console.log(translatedText, micLanguage.current);
			utterance = new SpeechSynthesisUtterance(translatedText);
			utterance.lang = "en-GB";
			console.log(utterance);
			speechSynthesis.speak(utterance);
			note.current = "";
		}
	}, [translatedText]);

	const handleButton = (language) => {
		micLanguage.current = language;
		console.log("language: " + language, micLanguage.current);
		setMicStatus(!micStatus);
	};

	return (
		<div className='container'>
			<button onClick={() => handleButton("hi-IN")}>
				{micStatus && micLanguage.current !== "kn-IN" ? (
					<div style={{ transform: "rotate(180deg)" }}>
						<FHGuestures
							// guestureHander={Blue}
							fileName={Blue}
						/>
					</div>
				) : (
					<img
						src={Other}
						alt='Other Icon'
						style={{ width: 300, height: 300 }}
					/>
				)}
			</button>

			<img src={Image} alt='Image' style={{ width: 120, height: 120 }} />
			<button onClick={() => handleButton("kn-IN")}>
				{micStatus && micLanguage.current === "kn-IN" ? (
					<FHGuestures
						// guestureHander={Blue}
						fileName={Blue}
					/>
				) : (
					<img src={Me} alt='Me Icon' style={{ width: 300, height: 300 }} />
				)}
			</button>
		</div>
	);
};

export default Translate;
